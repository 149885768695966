/**
 * 首页的接口访问方法
 *
 */
import { http } from '..';

/**
 * 查询所有角色
 */
function getAllRole() {
  return http.post('/selectAll');
}

/**
 * 分页查询角色
 */
function getAllRolePage(params) {
  return http.post('/selectRolePage', { ...params });
}

/**
 * 分页查询用户信息
 */
function getAllUserPage(params) {
  return http.post('/selectPage', { ...params });
}

/**
 * 分页查找所有的单位会员
 */
function getAllOfficeName(params) {
  return http.post('/unit/member/get/page', { ...params });
}

/**
 * 分页查找所有的个人会员
 */
function getAllPresonal(params) {
  return http.post('/personal/member/get/page', { ...params });
}

/**
 * 获取行业协会列表
 */
function getBankruptcyList(params) {
  return http.post('/association/get/list', { ...params });
}

/**
 * 分页查找所有的单位会员
 */
function getAllCompany(params) {
  return http.post('/unitMember/page', { ...params });
}

/**
 *文件下载
 */
function downloadFile(params) {
  return http.post('/file/download', { ...params });
}

/**
 * 分页查找所有文件信息
 */
function getAllFileList(params) {
  return http.post('/file/fileList', { ...params });
}

/**
 * 分页获取文章列表
 */
function getArticleList(params) {
  return http.post('/article/get/page/byDirCode', { ...params, flag: 1 });
}

/**
 * 联系我们
 */
function contactUs() {
  return http.post('/contact/info');
}

/**
 * 分页查询banner、ad
 */
function getBannerPage(params) {
  return http.post('/ad/page', { ...params });
}

/**
 * 理事会，监事会
 */
function getCouncilOrSupervisor(params) {
  return http.post('/position/getCouncilOrSupervisorByDirCode', {
    ...params,
  });
}

/**
 * 专门委员会，专业委员会的左侧menu
 */
async function getProfessionalOrSpecial(params) {
  return await http.post('/committee/get/page', { ...params });
}
/**
 * 专门委员会，专业委员会的内容列表
 */
function ProfessionalOrSpecialList(params) {
  return http.post('/committee/getCommitteeInfo', {
    ...params,
  });
}
/**
 * 专门委员会，专业委员会的内容列表
 */
function getCommitteeInfo(params) {
  return http.post('/committee/getCommitteeInfo', {
    ...params,
  });
}
/**首页理论探讨接口 */
function getMixArticleList() {
  return http.post('/article/getTheoreticalDiscussionData');
}

/**
 * 查找文章通过id
 */
function getArticlById(params) {
  return http.post('/article/get/byId', {
    ...params,
  });
}
/**
 * 裁判文书网
 */
function getPartner() {
  return http.post('/partner/get/page', { flag: 1, size: 99999 });
}
/**
 * 获取 ad 广告
 */
function getBannerOrAd(params) {
  return http.post('/ad/list', { ...params });
}
/**
 * 搜索
 */
function searchPageFront(params) {
  return http.post('/article/search/pageFront', { ...params });
}
/**
 * 根据article_id找到文章
 */
function getArticlebById(params) {
  return http.post('/article/get/byId', { ...params });
}

export default {
  getAllRole,
  getAllRolePage,
  getAllUserPage,
  getAllPresonal,
  getBankruptcyList,
  getAllOfficeName,
  getAllCompany,
  downloadFile,
  getAllFileList,
  getArticleList,
  contactUs,
  getBannerPage,
  getCouncilOrSupervisor,
  getProfessionalOrSpecial,
  ProfessionalOrSpecialList,
  getArticlById,
  getPartner,
  getBannerOrAd,
  searchPageFront,
  getArticlebById,
  getMixArticleList,
  getCommitteeInfo,
};
