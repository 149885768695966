/**
 * 全局方法类基类
 * @author PHCS
 * @author 子不语<zz@pohun.com>
 */

import Cookie from './Cookie';
import NetBase from './NetBase';
import Storage from './Storage';

const IS_DEV = process.env.NODE_ENV == 'development';

export default class Base {
  static IS_DEV = IS_DEV;

  static NetBase = NetBase;
  static Cookie = Cookie;
  static Storage = Storage;

  /** 复制对象或数组 */
  static cloneObjOrArr(obj: object) {
    let a = JSON.stringify(obj);
    let b = JSON.parse(a);
    return b;
  }

  /** 密码验证 */
  static checkOldPassword(rule: any, value: string, callback: Function) {
    const reg =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{6,24}$/;
    if (!value) {
      callback(new Error('请输入密码'));
    } else if (!reg.test(value)) {
      callback(new Error('密码错误，请重新输入'));
    } else {
      callback();
    }
  }
  /** 密码验证 */
  static checkPassword(rule: any, value: string, callback: Function) {
    const reg =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{6,24}$/;
    if (!value) {
      callback(new Error('请输入密码'));
    } else if (!reg.test(value)) {
      callback(new Error('密码6-24位，以数字、字母、特殊符号构成'));
    } else {
      callback();
    }
  }

  // 链接地址验证
  static checkAdLink(rule: any, value: string, callback: Function) {
    const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
    if (!value) {
      callback(new Error('请输入链接地址'));
    } else if (!reg.test(value)) {
      callback(new Error('请输入以http://或https://开头的地址'));
    } else {
      callback();
    }
  }

  /** 表单密码验证 */
  static LogincheckPassword(rule: any, value: string, callback: Function) {
    const reg =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{6,24}$/;
    if (!value) {
      callback(new Error('请输入密码'));
    } else if (!reg.test(value)) {
      callback(new Error('账户名或密码输入错误，请重新输入！'));
    } else {
      callback();
    }
  }

  /** 手机号验证 */
  static checkPhone(rule: any, value: string, callback: Function) {
    const reg = /^[0-9]{2,4}-[0-9]{5,10}$/;
    const reg1 = /^[0-9]{8,13}$/;
    if (!value) {
      callback(new Error('请输入电话号码'));
    } else if (!reg.test(value) && !reg1.test(value)) {
      callback(new Error('请输入正确的11位手机号码'));
    } else {
      callback();
    }
  }

  //非必填电话号码验证
  static NotRequirCheckPhone(rule: any, value: string, callback: Function) {
    const reg = /^[0-9]{2,4}-[0-9]{5,10}$/;
    const reg1 = /^[0-9]{8,13}$/;
    if (!value) {
      callback();
    } else if (!reg.test(value) && !reg1.test(value)) {
      callback(new Error('请输入正确的11位手机号码'));
    } else {
      callback();
    }
  }

  /** 邮箱验证 */
  static checkEmail(rule: any, value: string, callback: Function) {
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if (!value) {
      return callback(new Error('请输入电子邮箱'));
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的邮箱格式'));
      }
    }, 100);
  }

  //非必填邮箱地址验证
  static NotRequirCheckEmail(rule: any, value: string, callback: Function) {
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if (!value) {
      callback();
    } else {
      if (mailReg.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的邮箱格式'));
      }
    }
  }

  /** 图片验证 */
  static checkImage(rule: any, value: string, callback: Function) {
    if (!value) {
      return callback(new Error('请上传图片'));
    }
    {
      callback();
    }
  }
  /** 角色权限验证 */
  static checkPType(rule: any, value: string, callback: Function) {
    if (value.length === 0) {
      callback(new Error('请选择角色权限'));
    } else {
      const str = value.toString();
      if (
        str.search('B0000') != -1 &&
        str.search('B0001') == -1 &&
        str.search('B0002') == -1 &&
        str.search('B0003') == -1 &&
        str.search('B0004') == -1 &&
        str.search('B0005') == -1 &&
        str.search('B0006') == -1 &&
        str.search('B0007') == -1 &&
        str.search('B0008') == -1 &&
        str.search('B0009') == -1
      ) {
        callback(new Error('请选择对应的二级菜单'));
      } else if (
        str.search('C0000') != -1 &&
        str.search('C0001') == -1 &&
        str.search('C0002') == -1
      ) {
        callback(new Error('请选择对应的二级菜单'));
      } else if (
        str.search('D0000') != -1 &&
        str.search('D0001') == -1 &&
        str.search('D0002') == -1 &&
        str.search('D0003') == -1 &&
        str.search('D0004') == -1 &&
        str.search('D0005') == -1 &&
        str.search('D0006') == -1
      ) {
        callback(new Error('请选择对应的二级菜单'));
      } else if (
        str.search('E0000') != -1 &&
        str.search('E0001') == -1 &&
        str.search('E0002') == -1
      ) {
        callback(new Error('请选择对应的二级菜单'));
      } else if (
        str.search('F0000') != -1 &&
        str.search('F0001') == -1 &&
        str.search('F0002') == -1 &&
        str.search('F0003') == -1
      ) {
        callback(new Error('请选择对应的二级菜单'));
      } else if (
        str.search('G0000') != -1 &&
        str.search('G0001') == -1 &&
        str.search('G0002') == -1 &&
        str.search('G0003') == -1 &&
        str.search('G0004') == -1
      ) {
        callback(new Error('请选择对应的二级菜单'));
      } else if (
        str.search('H0000') != -1 &&
        str.search('H0001') == -1 &&
        str.search('H0002') == -1
      ) {
        callback(new Error('请选择对应的二级菜单'));
      } else {
        callback();
      }
    }
  }
}
