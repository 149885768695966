import { GeneralContentConfig2Routes } from '@/config/contentConfig';
import {
  createRouter,
  useRoute,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router';

/***
 * 注意: admin下的路由参数,name值为完整路由,
 * 在config.ts处理进行跳转
 * 例子:
 *  {
 *    path: 'index',
      name: '/admin/inform/index',     // 完整路径
      title: '协会简介',
      component: () => import('@/views/admin/dynamic/Inform.vue'),
 * }
 */

/**
 * admin端公共父容器组件
 */

const Layout = () => import('@/views/admin/admin-components/Layout.vue');
const redirectPath = /Android|webOS|iPhone|iPad|BlackBerry|iPod/i.test(
  navigator.userAgent,
)
  ? '/m_Index'
  : '/p_Index';
/**
 * pc端前台路由
 */

const PCindexRouters = [
  {
    path: '/p_Index',
    name: 'p_Index',
    component: () => import('@/views/pc/LayoutIndex.vue'),
    meta: {
      type: 'pc',
    },
    children: [
      {
        path: '/p_Index',
        name: 'p_Index',
        component: () => import('@/views/pc/Index.vue'),
        meta: {
          type: 'pc',
        },
      },
    ],
  },
];

const PCWebRouters = [
  {
    path: '/p_Generalization',
    name: 'p_Generalization',
    component: () => import('@/views/pc/Layout.vue'),
    meta: {
      type: 'pc',
    },
    children: [
      ...GeneralContentConfig2Routes(),
      {
        path: '/p_contactUs',
        name: 'p_contactUs',
        component: () =>
          import('@/components/pc/general-content/content/ContactUs.vue'),
        meta: {
          type: 'pc',
        },
      },
      {
        path: '/p_search',
        name: 'p_search',
        component: () =>
          import('@/components/pc/general-content/content/SearchResult.vue'),
        meta: {
          type: 'pc',
        },
      },
      {
        path: '/Detail',
        name: 'Detail',
        component: () => import('@/views/pc/Detail.vue'),
        meta: {
          type: 'pc',
        },
      },
      {
        path: '/LeaderDetail',
        name: 'LeaderDetail',
        component: () => import('@/views/pc/LeaderDetail.vue'),
        meta: {
          type: 'pc',
        },
      },
    ],
  },
];

/**
 * pc后台路由
 */
const adminRouters = [
  {
    path: '/admin',
    // name: 'admin',
    title: '首页',
    component: () => import('@/views/admin/Layout.vue'),
    meta: {
      type: 'pc',
    },
    children: [
      {
        path: '/admin',
        name: 'admin',
        title: '首页',
        limits: 'A0000',
        icon: '',
        component: () => import('@/views/admin/Index.vue'),
        children: [],
      },
      // 协会概况
      {
        path: 'introductory',
        name: 'Introductory',
        title: '协会概况',
        limits: 'B0000',
        icon: 'orgicon-shouye-1xiehuitongzhitubiao',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/introductory/index',
            title: '协会简介',
            limits: 'B0001',
            component: () =>
              import('@/views/admin/introductory/Introductory.vue'),
          },
          {
            path: 'constitution',
            name: '/admin/introductory/constitution',
            title: '协会章程',
            limits: 'B0002',
            component: () =>
              import('@/views/admin/introductory/Constitution.vue'),
          },
          {
            path: 'Leader',
            name: '/admin/introductory/Leader',
            title: '协会领导',
            limits: 'B0003',
            component: () => import('@/views/admin/introductory/Leader.vue'),
          },
          {
            path: 'institution',
            name: '/admin/introductory/institution',
            title: '协会制度',
            limits: 'B0004',
            component: () =>
              import('@/views/admin/introductory/Institution.vue'),
          },
          {
            path: 'council',
            name: '/admin/introductory/council',
            title: '理事会',
            limits: 'B0005',
            component: () => import('@/views/admin/introductory/Council.vue'),
          },
          {
            path: 'supervise',
            name: '/admin/introductory/supervise',
            title: '监事会',
            limits: 'B0006',
            component: () => import('@/views/admin/introductory/Supervise.vue'),
          },
          {
            path: 'specialCommittee',
            name: '/admin/introductory/specialCommittee',
            title: '专门委员会',
            limits: 'B0008',
            component: () =>
              import('@/views/admin/introductory/SpecialCommittee.vue'),
          },
          {
            path: 'professionalCommittee',
            name: '/admin/introductory/professionalCommittee',
            title: '专业委员会',
            limits: 'B0009',
            component: () =>
              import('@/views/admin/introductory/ProfessionalCommittee.vue'),
          },
          {
            path: 'expertCommittee',
            name: '/admin/introductory/expertCommittee',
            title: '专家委员',
            limits: 'B0010',
            component: () =>
              import('@/views/admin/introductory/ExpertCommittee.vue'),
          },
          {
            path: 'specialCommitteeEditor',
            name: '/admin/introductory/SpecialCommitteeEditor',
            title: '委员会编辑',
            limits: 'B0013',
            component: () =>
              import('@/views/admin/introductory/SpecialCommitteeEditor.vue'),
          },
          {
            path: 'info',
            name: '/admin/introductory/info',
            title: '会员权益专门委员会',
            limits: 'A0010',
            hideInMenu: true,
            component: () =>
              import('@/views/admin/introductory/CommitteeDetials.vue'),
          },
          {
            path: 'editEditor',
            name: '/admin/introductory/editEditor',
            title: '西安市破产管理人协会理事会议规则',
            limits: 'A0011',
            hideInMenu: true,
            component: () =>
              import('@/views/admin/admin-components/EditEditor.vue'),
          },
        ],
      },
      // 协会动态
      {
        path: 'inform',
        name: 'informName',
        title: '协会动态',
        limits: 'C0000',
        icon: 'orgicon-shouye-2touzirenzhaomugonggao',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/inform/index',
            title: '协会通知',
            limits: 'C0001',
            component: () =>
              import('@/views/admin/dynamic/AssociationNotice.vue'),
          },
          {
            path: 'importantNews',
            name: '/admin/inform/importantNews',
            title: '协会要闻',
            limits: 'C0002',
            component: () => import('@/views/admin/dynamic/ImportantNews.vue'),
          },
          // {
          //   path: 'memberDynamics',
          //   name: '/admin/inform/memberDynamics',
          //   title: '会员动态',
          //   limits: 'C0003',
          //   component: () => import('@/views/admin/dynamic/memberDynamics.vue'),
          // },
          // {
          //   path: 'managerForum',
          //   name: '/admin/inform/managerForum',
          //   title: '管理人论坛',
          //   limits: 'C0004',
          //   component: () => import('@/views/admin/dynamic/managerForum.vue'),
          // },
          // {
          //   path: 'dynamicSeminar',
          //   name: '/admin/inform/dynamicSeminar',
          //   title: '专题研讨',
          //   limits: 'C0005',
          //   component: () => import('@/views/admin/dynamic/dynamicSeminar.vue'),
          // },
        ],
      },
      // 新闻动态
      {
        path: 'propaganda',
        name: 'propaganda',
        title: '新闻动态',
        limits: 'D0000',
        icon: 'icon-a-huaban51',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/propaganda/index',
            title: '新闻动态',
            limits: 'D0001',
            component: () =>
              import('@/views/admin/propaganda/industryInformation.vue'),
          },
          {
            path: 'activityPublicity',
            name: '/admin/propaganda/activityPublicity',
            title: '活动宣传',
            limits: 'D0002',
            component: () =>
              import('@/views/admin/propaganda/activityPublicity.vue'),
          },
          {
            path: 'propagandaEdit',
            name: '/admin/propaganda/propagandaEdit',
            title: '添加',
            limits: 'D0003',
            hideInMenu: true,
            component: () =>
              import('@/views/admin/propaganda/propagandaEditView.vue'),
          },
        ],
      },
      // 公告通知
      {
        path: 'announcement',
        name: 'announcement',
        title: '公告通知',
        limits: 'E0000',
        icon: 'orgicon-shouye-6pochanxiehui',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/announcement/index',
            title: '投资人招募公告',
            limits: 'E0001',
            component: () =>
              import('@/views/admin/announcement/RecruitingInvestors.vue'),
          },
          {
            path: 'creditor',
            name: '/admin/announcement/creditor',
            title: '债权人会议公告',
            limits: 'E0002',
            component: () => import('@/views/admin/announcement/Creditor.vue'),
          },
          {
            path: 'manager',
            name: '/admin/announcement/manager',
            title: '管理人公告',
            limits: 'E0003',
            component: () => import('@/views/admin/announcement/Manager.vue'),
          },
          {
            path: 'bankruptcyCase',
            name: '/admin/announcement/bankruptcyCase',
            title: '破产案件信息公告',
            limits: 'E0004',
            component: () =>
              import('@/views/admin/announcement/BankruptcyCase.vue'),
          },
          {
            path: 'assetAuction',
            name: '/admin/announcement/assetAuction',
            title: '重大资产拍卖公告',
            limits: 'E0005',
            component: () =>
              import('@/views/admin/announcement/AssetAuction.vue'),
          },
          {
            path: 'preReforming',
            name: '/admin/announcement/preReforming',
            title: '预重整公告',
            limits: 'E0006',
            component: () =>
              import('@/views/admin/announcement/PreReforming.vue'),
          },
        ],
      },
      // 理论探讨
      {
        path: 'theoretical',
        name: 'theoretical',
        title: '理论探讨',
        limits: 'F0000',
        icon: 'orgicon-shouye-4liluntantao',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/theoretical/index',
            title: '专题研讨',
            limits: 'F0001',
            component: () => import('@/views/admin/theoretical/seminar.vue'),
          },
          {
            path: 'systemDiscussion',
            name: '/admin/theoretical/systemDiscussion',
            title: '制度探讨',
            limits: 'F0002',
            component: () =>
              import('@/views/admin/theoretical/systemDiscussion.vue'),
          },
          {
            path: 'bankruptcyView',
            name: '/admin/theoretical/bankruptcyView',
            title: '破产观点',
            limits: 'F0003',
            component: () =>
              import('@/views/admin/theoretical/bankruptcyView.vue'),
          },
          {
            path: 'theoreticalResearch',
            name: '/admin/theoretical/theoreticalResearch',
            title: '理论研究',
            limits: 'F0004',
            component: () => import('@/views/admin/theoretical/Research.vue'),
          },
          {
            path: 'classicCase',
            name: '/admin/theoretical/classicCase',
            title: '典型案例',
            limits: 'F0005',
            component: () =>
              import('@/views/admin/theoretical/ClassicCase.vue'),
          },
          {
            path: 'theoreticalEdit',
            name: '/admin/theoretical/theoreticalEdit',
            title: '添加',
            limits: 'F0006',
            hideInMenu: true,
            component: () =>
              import('@/views/admin/theoretical/theoreticalEdit.vue'),
          },
        ],
      },
      // 培训学习
      {
        path: 'training',
        name: 'training',
        title: '培训学习',
        limits: 'G0000',
        icon: 'orgicon-shouye-5peixunxuexi',
        component: Layout,
        children: [
          // {
          //   path: 'index',
          //   name: '/admin/training/index',
          //   title: '培训通知',
          //   limits: 'F0001',
          //   component: () => import('@/views/admin/training/Notice.vue'),
          // },
          {
            path: 'index',
            name: '/admin/training/index',
            title: '培训动态',
            limits: 'G0001',
            component: () => import('@/views/admin/training/Trends.vue'),
          },
          {
            path: 'course',
            name: '/admin/training/course',
            title: '培训课程',
            limits: 'G0002',
            component: () => import('@/views/admin/training/Course.vue'),
          },
          {
            path: 'trendsEditor',
            name: '/admin/training/trendsEditor',
            title: '新增新闻',
            limits: 'G0003',
            hideInMenu: true,
            component: () => import('@/views/admin/training/TrendsEditor.vue'),
          },
        ],
      },
      // 法律法规
      {
        path: 'law',
        name: 'law',
        title: '法律法规',
        limits: 'H0000',
        icon: 'orgicon-shouye-3falvfagui',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/law/index',
            title: '法律法规、司法解释',
            limits: 'H0001',
            component: () => import('@/views/admin/law/Rule.vue'),
          },
          {
            path: 'regulations',
            name: '/admin/law/regulations',
            title: '部门规章、规范性法律文件',
            limits: 'H0002',
            component: () => import('@/views/admin/law/Regulations.vue'),
          },
          {
            path: 'court',
            name: '/admin/law/court',
            title: '地方法院指导文件',
            limits: 'H0003',
            component: () => import('@/views/admin/law/Court.vue'),
          },
          {
            path: 'government',
            name: '/admin/law/government',
            title: '地方政府指导文件',
            limits: 'H0004',
            component: () => import('@/views/admin/law/Government.vue'),
          },
        ],
      },
      // 会员单位
      {
        path: 'memberUnits',
        name: 'memberUnits',
        title: '会员单位',
        limits: 'I0000',
        icon: 'icon-a-huaban9',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/memberUnits/index',
            title: '单位会员',
            limits: 'I0001',
            component: () =>
              import('@/views/admin/memberUnits/firstManagers.vue'),
          },
          {
            path: 'thirdManagers',
            name: '/admin/memberUnits/thirdManagers',
            title: '特邀会员',
            limits: 'I0003',
            component: () =>
              import('@/views/admin/memberUnits/thirdManagers.vue'),
          },
          {
            path: 'otherManagers',
            name: '/admin/memberUnits/otherManagers',
            title: '招募会员',
            limits: 'I0004',
            component: () =>
              import('@/views/admin/memberUnits/otherManagers.vue'),
          },
          {
            path: 'secondManagers',
            name: '/admin/memberUnits/secondManagers',
            title: '个人会员',
            limits: 'I0002',
            component: () =>
              import('@/views/admin/memberUnits/secondManagers.vue'),
          },
        ],
      },
      // 文件下载
      {
        path: 'fileDownload',
        name: 'fileDownload',
        title: '文件下载',
        limits: 'J0000',
        icon: 'orgicon-a-huaban10',
        component: Layout,
        children: [
          {
            path: 'index',
            name: '/admin/fileDownload/index',
            title: '协会制度',
            limits: 'J0001',
            component: () =>
              import('@/views/admin/file-download/AssociationSystem.vue'),
          },
          {
            path: 'apply',
            name: '/admin/fileDownload/apply',
            title: '入会申请',
            limits: 'J0002',
            component: () => import('@/views/admin/file-download/Apply.vue'),
          },
        ],
      },
      // 联系我们
      {
        path: '/contact',
        name: '/admin/contact',
        title: '联系我们',
        limits: 'K0000',
        icon: 'orgicon-a-huaban11',
        component: () => import('@/views/admin/Contact.vue'),
        children: [],
      },
      // ad管理
      {
        path: '/banner',
        name: '/admin/banner',
        title: 'banner/ad管理',
        limits: 'L0000',
        icon: 'orgicon-a-huaban12',
        component: () => import('@/views/admin/banner/Index.vue'),
        children: [],
      },
      // 链接管理
      {
        path: '/linkManagement',
        name: '/admin/linkManagement',
        title: '链接管理',
        limits: 'M0000',
        icon: 'orgicon-a-huaban13',
        component: () => import('@/views/admin/link-management/Index.vue'),
        children: [],
      },
      // 角色管理
      {
        path: '/role',
        name: '/admin/role',
        title: '角色管理',
        limits: 'N0000',
        icon: 'orgicon-a-huaban14',
        component: () => import('@/views/admin/role-management/Index.vue'),
        children: [],
      },
      // 人员管理
      {
        path: '/personnel',
        name: '/admin/personnel',
        title: '人员管理',
        limits: 'P0000',
        icon: 'orgicon-a-huaban15',
        component: () => import('@/views/admin/personnel-management/Index.vue'),
        children: [],
      },
    ],
  },
];
const mobileIndexRouters = [
  {
    path: '/m_Index',
    component: () => import('@/views/mobile/IndexLayout.vue'),
    meta: {
      type: 'mobile',
      level: 1,
    },
    children: [
      {
        path: '',
        name: 'm_Index',
        component: () => import('@/views/mobile/Index.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
        children: [],
      },
    ],
  },
];
/**
 * 移动端前台路由
 */
const mobileRouters = [
  {
    path: '/m_Index',
    component: () => import('@/views/mobile/Layout.vue'),
    meta: {
      type: 'mobile',
      level: 1,
    },
    children: [
      // {
      //   path: '',
      //   name: 'm_Index',
      //   component: () => import('@/views/mobile/Index.vue'),
      //   meta: {
      //     type: 'mobile',
      //     level: 2,
      //   },
      //   children: [],
      // },
      {
        path: '/m_SearchResult',
        name: 'm_SearchResult',
        component: () => import('@/views/mobile/components/SearchResult.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Detail',
        name: 'm_Detail',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_contactUs',
        name: 'm_contactUs',
        component: () => import('@/views/mobile/ContactUs.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization',
        name: 'm_Generalization',
        redirectPath: '/m_Generalization',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      /** 协会概况 专门委员会 */
      {
        path: '/m_Generalization/specialCommittee',
        name: 'm_GeneralizationSpecialCommittee',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/specialCommittee/:id',
        name: 'm_GeneralizationSpecialCommitteeWithId',
        component: () => import('@/views/mobile/CommitteeDetials.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      /** 协会概况 专业委员会 */
      {
        path: '/m_Generalization/professionalCommittee',
        name: 'm_GeneralizationProfessionalCommittee',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/professionalCommittee/:id',
        name: 'm_GeneralizationProfessionalCommitteeWithId',
        component: () => import('@/views/mobile/CommitteeDetials.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },

      {
        path: '/m_Generalization/:data',
        name: 'm_GeneralizationWithData',
        component: () => import('@/views/mobile/Generalization.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      {
        path: '/m_Generalization/:data/Detail/:id',
        name: 'm_GeneralizationWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
          level: 2,
        },
      },
      /** 协会动态 */
      {
        path: '/m_Dynamic',
        name: 'm_Dynamic',
        component: () => import('@/views/mobile/Dynamic.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Dynamic/:data',
        name: 'm_DynamicWithData',
        component: () => import('@/views/mobile/Dynamic.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Dynamic/:data/Detail/:id',
        name: 'm_DynamicWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /**活动宣传*/
      {
        path: '/m_Propaganda',
        name: 'm_Propaganda',
        component: () => import('@/views/mobile/Propaganda.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Propaganda/:data',
        name: 'm_PropagandaData',
        component: () => import('@/views/mobile/Propaganda.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Propaganda/:data/Detail/:id',
        name: 'm_PropagandaWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 通知公告 */
      {
        path: '/m_Notification',
        name: 'm_Notification',
        component: () => import('@/views/mobile/Notification.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Notification/:data',
        name: 'm_NotificationWithData',
        component: () => import('@/views/mobile/Notification.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Notification/:data/Detail/:id',
        name: 'm_NotificationWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 理论探讨 */
      {
        path: '/m_Theory',
        name: 'm_Theory',
        component: () => import('@/views/mobile/Theory.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Theory/:data',
        name: 'm_TheoryWithData',
        component: () => import('@/views/mobile/Theory.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Theory/:data/Detail/:id',
        name: 'm_TheoryWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 培训学习 */
      {
        path: '/m_Train',
        name: 'm_Train',
        component: () => import('@/views/mobile/Train.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Train/:data',
        name: 'm_TrainWithData',
        component: () => import('@/views/mobile/Train.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_Train/:data/Detail/:id',
        name: 'm_TrainWithDataWithDetail',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 法律法规 */
      {
        path: '/m_lawsRegulations',
        name: 'm_lawsRegulations',
        component: () => import('@/views/mobile/LawsRegulations.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_lawsRegulations/:data',
        name: 'm_lawsRegulationsWithData',
        component: () => import('@/views/mobile/LawsRegulations.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_lawsRegulations/:data/Detail/:id',
        name: 'm_lawsRegulationsWithDataWithDetailId',
        component: () => import('@/views/mobile/Detail.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /**会员单位 */
      {
        path: '/m_memberUnits',
        name: 'm_memberUnits',
        component: () => import('@/views/mobile/MemberUnits.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_memberUnits/:data',
        name: 'm_memberUnitsWithData',
        component: () => import('@/views/mobile/MemberUnits.vue'),
        meta: {
          type: 'mobile',
        },
      },
      /** 文件下载 */
      {
        path: '/m_download',
        name: 'm_download',
        component: () => import('@/views/mobile/Download.vue'),
        meta: {
          type: 'mobile',
        },
      },
      {
        path: '/m_download/:data',
        name: 'm_downloadWithData',
        component: () => import('@/views/mobile/Download.vue'),
        meta: {
          type: 'mobile',
        },
      },
    ],
  },
];
/**
 * 全部路由
 */
const routes = [
  {
    path: '/',
    redirect: redirectPath,
  },
  ...PCindexRouters,
  ...PCWebRouters,
  ...adminRouters,
  ...mobileIndexRouters,
  ...mobileRouters,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      type: 'pc',
    },
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/login',
    name: '/login',
    meta: {
      type: 'pc',
    },
    component: () => import('@/views/admin/Login.vue'),
  },
  // {
  //   path: '/Detail',
  //   name: 'Detail',
  //   component: () => import('@/views/pc/Detail.vue'),
  //   meta: {
  //     type: 'pc'
  //   }
  // }
];
const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, form, next) => {
  // 当移动端访问pc端页面时,
  if (
    /Android|webOS|iPhone|iPad|BlackBerry|iPod/i.test(navigator.userAgent) &&
    to.meta.type !== 'mobile'
  ) {
    const path = router.options.routes.filter(
      (ele) => ele?.name?.split('_')[1] === to?.name?.split('_')[1],
    )[0].path;
    if (path !== '/') {
      next('m_' + path.split('_')[1]);
    } else {
      next('/');
    }
  }
  // 当pc端访问移动端页面时
  if (
    !/Android|webOS|iPhone|iPad|BlackBerry|iPod/i.test(navigator.userAgent) &&
    to.meta.type !== 'pc'
  ) {
    const currentRoute = router.options.routes.filter(
      (ele) => ele?.name?.split('_')[1] === to?.name?.split('_')[1],
    )[0];
    if (currentRoute) {
      next('p_' + currentRoute.path.split('_')[1]);
    } else {
      next('/');
    }
  }
  if (to.name === '/admin/introductory/info') {
    window.localStorage.setItem('isDetail', '1');
  } else {
    window.localStorage.removeItem('isDetail');
  }
  next();
});

router.afterEach(() => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
});

export default router;
