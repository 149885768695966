import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import './index.css';
import './assets/css/setting.css';
import './assets/css/global.css';
import './assets/fonts/font.css';
const win: any = window;

if (process.env.NODE_ENV === 'development') {
  if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in win) {
    win.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = App;
  }
}
const app = createApp(App);
app.config.globalProperties.$filters = {
  lineFeed(value) {
    let str = '';
    if (value) {
      str = value.replace('\\n', '<br/>');
    }
    return str;
  },
  deleteN(value) {
    let str = '';
    if (value) {
      str = value.replace('\\n', '');
    }
    return str;
  },
};
app.use(router);
app.use(store);
app.use(ElementPlus, { size: 'mini' });

app.mount('#app');

export default app;
